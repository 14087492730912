import React, { useEffect, useState } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { UtilityButton } from '../../styles/Button.styled';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { clearErrors, getElectricityAndFuelDataById, getFootprintById, putElectricityDetailsById, reset } from '../../../features/footprint/footprintSlice';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import tippy from 'tippy.js';

type Props = {}

interface IElectricityErrors {
    solarAnnualUsageOnSite?: string,
    solarAnnualQuantityExported?: string,
    windAnnualUsageOnSite?: string,
    windAnnualQuantityExported?: string,
    biomassAnnualUsageOnSite?: string,
    biomassAnnualQuantityExported?: string,
    biogasAnnualUsageOnSite?: string,
    biogasAnnualQuantityExported?: string,
    groundAnnualUsageOnSite?: string,
    groundAnnualQuantityExported?: string,
    gridAnnualUsageOnSite?: string
}

const Electricity = (props: Props) => {

    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();

    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, electricityAndFuel, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        setActiveWideStep(5);
        setActiveMobileStep(4);

        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getElectricityAndFuelDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Electricity saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/fuel");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        dispatch(getDropdownValues({ section: "ElectricityAndFuelInput" }))

    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (electricityDetails: { electricityType: string, annualUsage: number, annualExported?: number }[]) => {
        var solar = electricityDetails.find(x => x.electricityType === "Solar");
        var wind = electricityDetails.find(x => x.electricityType === "Wind");
        var biomass = electricityDetails.find(x => x.electricityType === "BiomassCHP");
        var biogas = electricityDetails.find(x => x.electricityType === "BiogasCHP");
        var grid = electricityDetails.find(x => x.electricityType === "GridElectricity");

        var defaultValues = {
            solarAnnualUsageOnSite: 0,
            solarAnnualQuantityExported: 0,
            windAnnualUsageOnSite: 0,
            windAnnualQuantityExported: 0,
            biomassAnnualUsageOnSite: 0,
            biomassAnnualQuantityExported: 0,
            biogasAnnualUsageOnSite: 0,
            biogasAnnualQuantityExported: 0,
            gridAnnualUsageOnSite: 0
        }

        if (!(
            solar?.annualUsage ||
            solar?.annualExported ||
            wind?.annualUsage ||
            wind?.annualExported ||
            biomass?.annualUsage ||
            biomass?.annualExported ||
            biogas?.annualUsage ||
            biogas?.annualExported ||
            grid?.annualUsage
        )) {
            return defaultValues
        } else {
            return {
                solarAnnualUsageOnSite: solar?.annualUsage,
                solarAnnualQuantityExported: solar?.annualExported,
                windAnnualUsageOnSite: wind?.annualUsage,
                windAnnualQuantityExported: wind?.annualExported,
                biomassAnnualUsageOnSite: biomass?.annualUsage,
                biomassAnnualQuantityExported: biomass?.annualExported,
                biogasAnnualUsageOnSite: biogas?.annualUsage,
                biogasAnnualQuantityExported: biogas?.annualExported,
                gridAnnualUsageOnSite: grid?.annualUsage
            }
        }

    }

    tippy('.grid-tooltip', {
        content: "Enter annual usage of electricity on site from this source. If any additional renewable energy was generated and exported back to the grid, please include the quantity here.",
    });

    tippy('.solar-tooltip', {
        content: "Enter annual usage of electricity on site from this source. If any additional renewable energy was generated and exported back to the grid, please include the quantity here.",
    });

    tippy('.wind-tooltip', {
        content: "Enter annual usage of electricity on site from this source. If any additional renewable energy was generated and exported back to the grid, please include the quantity here.",
    });

    tippy('.biomass-tooltip', {
        content: "Enter annual usage of electricity on site from this source. If any additional renewable energy was generated and exported back to the grid, please include the quantity here.",
    });

    tippy('.biogas-tooltip', {
        content: "Enter annual usage of electricity on site from this source. If any additional renewable energy was generated and exported back to the grid, please include the quantity here.",
    });


    return (
        <div>
            <InputFormHeading>Electricity and fuel - electricity</InputFormHeading>
            {loading ? <Loader /> :
                <Formik
                    initialValues={parseDataIntoForm(electricityAndFuel?.electricity?.electricityDetails ?? [])}
                    validate={values => {
                        const errors: IElectricityErrors = {};

                        if (!values.solarAnnualUsageOnSite && values.solarAnnualUsageOnSite !== 0) {
                            errors.solarAnnualUsageOnSite = 'Required';
                        }
                        if (!values.solarAnnualQuantityExported && values.solarAnnualQuantityExported !== 0) {
                            errors.solarAnnualQuantityExported = 'Required';
                        }
                        if (!values.windAnnualUsageOnSite && values.windAnnualUsageOnSite !== 0) {
                            errors.windAnnualUsageOnSite = 'Required';
                        }
                        if (!values.windAnnualQuantityExported && values.windAnnualQuantityExported !== 0) {
                            errors.windAnnualQuantityExported = 'Required';
                        }
                        if (!values.biomassAnnualUsageOnSite && values.biomassAnnualUsageOnSite !== 0) {
                            errors.biomassAnnualUsageOnSite = 'Required';
                        }
                        if (!values.biomassAnnualQuantityExported && values.biomassAnnualQuantityExported !== 0) {
                            errors.biomassAnnualQuantityExported = 'Required';
                        }
                        if (!values.biogasAnnualUsageOnSite && values.biogasAnnualUsageOnSite !== 0) {
                            errors.biogasAnnualUsageOnSite = 'Required';
                        }
                        if (!values.biogasAnnualQuantityExported && values.biogasAnnualQuantityExported !== 0) {
                            errors.biogasAnnualQuantityExported = 'Required';
                        }
                        if (!values.gridAnnualUsageOnSite && values.gridAnnualUsageOnSite !== 0) {
                            errors.gridAnnualUsageOnSite = 'Required';
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            setSubmitting(false);
                        }, 400);

                        // call dispatch action
                        dispatch(putElectricityDetailsById({
                            solarAnnualUsageOnSite: values.solarAnnualUsageOnSite ?? 0,
                            solarAnnualQuantityExported: values.solarAnnualQuantityExported ?? 0,
                            windAnnualUsageOnSite: values.windAnnualUsageOnSite ?? 0,
                            windAnnualQuantityExported: values.windAnnualQuantityExported ?? 0,
                            biomassAnnualUsageOnSite: values.biomassAnnualUsageOnSite ?? 0,
                            biomassAnnualQuantityExported: values.biomassAnnualQuantityExported ?? 0,
                            biogasAnnualUsageOnSite: values.biogasAnnualUsageOnSite ?? 0,
                            biogasAnnualQuantityExported: values.biogasAnnualQuantityExported ?? 0,
                            gridAnnualUsageOnSite: values.gridAnnualUsageOnSite ?? 0
                        }))

                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <InputForm onSubmit={handleSubmit}>

                            <InputFormDescription>
                                Enter the total quantity of electricity used on the site, including from the grid and renewable sources. <br />

                                For renewables, if electricity is sold to the national grid then also state the quantity exported.
                            </InputFormDescription>

                            <table data-role="table" data-mode="reflow" className="ui-table ui-responsive ui-table-reflow my-custom-breakpoint my-4">
                                <thead>
                                    <tr>
                                        <th data-priority="persist">Source</th>
                                        <th data-priority="1">Annual usage on-site (kWh)</th>
                                        <th data-priority="2">Annual quantity exported to grid(kWh)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className='align-middle'>
                                            <label>
                                                Grid electricity
                                            </label>
                                        </th>
                                        <td>
                                            <div>
                                                <input
                                                    name="gridAnnualUsageOnSite"
                                                    type='number'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.gridAnnualUsageOnSite}
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.gridAnnualUsageOnSite && touched.gridAnnualUsageOnSite && errors.gridAnnualUsageOnSite}</ErrorText>
                                            </div>
                                        </td>
                                        <td>
                                            {/* <div>
                                        <input
                                            name="gridAnnualQuantityExported"
                                            type='number'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.gridAnnualQuantityExported}
                                            className='form-control'
                                        />
                                        <ErrorText>{errors.gridAnnualQuantityExported && touched.gridAnnualQuantityExported && errors.gridAnnualQuantityExported}</ErrorText>
                                    </div> */}
                                        </td>
                                        <td className='align-middle'>
                                            <UtilityButton className='grid-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='align-middle'>
                                            <label>
                                                Solar
                                            </label>
                                        </th>
                                        <td>
                                            <div>
                                                <input
                                                    name="solarAnnualUsageOnSite"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.solarAnnualUsageOnSite}
                                                    type='number'
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.solarAnnualUsageOnSite && touched.solarAnnualUsageOnSite && errors.solarAnnualUsageOnSite}</ErrorText>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    name="solarAnnualQuantityExported"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.solarAnnualQuantityExported}
                                                    type='number'
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.solarAnnualQuantityExported && touched.solarAnnualQuantityExported && errors.solarAnnualQuantityExported}</ErrorText>
                                            </div>
                                        </td>
                                        <td className='align-middle'>
                                            <UtilityButton className='solar-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='align-middle'>
                                            <label>
                                                Wind
                                            </label>
                                        </th>
                                        <td>
                                            <div>
                                                <input
                                                    name="windAnnualUsageOnSite"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.windAnnualUsageOnSite}
                                                    type='number'
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.windAnnualUsageOnSite && touched.windAnnualUsageOnSite && errors.windAnnualUsageOnSite}</ErrorText>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    name="windAnnualQuantityExported"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.windAnnualQuantityExported}
                                                    type='number'
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.windAnnualQuantityExported && touched.windAnnualQuantityExported && errors.windAnnualQuantityExported}</ErrorText>
                                            </div>
                                        </td>
                                        <td className='align-middle'>
                                            <UtilityButton className='wind-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='align-middle'>
                                            <label>
                                                Biomass CHP
                                            </label>
                                        </th>
                                        <td>
                                            <div>
                                                <input
                                                    name="biomassAnnualUsageOnSite"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.biomassAnnualUsageOnSite}
                                                    type='number'
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.biomassAnnualUsageOnSite && touched.biomassAnnualUsageOnSite && errors.biomassAnnualUsageOnSite}</ErrorText>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    name="biomassAnnualQuantityExported"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.biomassAnnualQuantityExported}
                                                    type='number'
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.biomassAnnualQuantityExported && touched.biomassAnnualQuantityExported && errors.biomassAnnualQuantityExported}</ErrorText>
                                            </div>
                                        </td>
                                        <td className='align-middle'>
                                            <UtilityButton className='biomass-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className='align-middle'>
                                            <label>
                                                Biogas CHP
                                            </label>
                                        </th>
                                        <td>
                                            <div>
                                                <input
                                                    name="biogasAnnualUsageOnSite"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type='number'
                                                    value={values.biogasAnnualUsageOnSite}
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.biogasAnnualUsageOnSite && touched.biogasAnnualUsageOnSite && errors.biogasAnnualUsageOnSite}</ErrorText>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    name="biogasAnnualQuantityExported"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type='number'
                                                    value={values.biogasAnnualQuantityExported}
                                                    className='form-control'
                                                    disabled={inputIsLocked()}
                                                />
                                                <ErrorText>{errors.biogasAnnualQuantityExported && touched.biogasAnnualQuantityExported && errors.biogasAnnualQuantityExported}</ErrorText>
                                            </div>
                                        </td>
                                        <td className='align-middle'>
                                            <UtilityButton className='biogas-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className='d-flex w-100 justify-content-center'>
                                <InputFormButtonsContainer>
                                    <InputFormButton type="button" onClick={() => navigate("/input/purchasedBeddingTypes")}>
                                        Back
                                    </InputFormButton>

                                    {inputIsLocked() ? (
                                        <>
                                            <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                                Continue
                                            </InputFormSaveButton>
                                        </>
                                    ) : (
                                        <>
                                            <InputFormButton disabled={isSubmitting}>
                                                Save
                                            </InputFormButton>

                                            <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                                Save & Continue
                                            </InputFormSaveButton>
                                        </>
                                    )}
                                </InputFormButtonsContainer>
                            </div>
                        </InputForm>
                    )}
                </Formik>
            }
        </div>
    )
}

export default Electricity