import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IHoldingData, IHoldingState, IHoldingValues } from "./holdingSlice.interfaces";

axios.defaults.withCredentials = true;

const initialState: IHoldingState = {
    loading: false,
}

export const getHoldings = createAsyncThunk(
    "holding/get",
    async (props: IHoldingData, { rejectWithValue }) => {
        try {
            var { userProfileId } = props;
            var url = '/api/holdinginformation';
            if (userProfileId) url = url + `?userProfileId=${userProfileId}`
            const { data } = await axios.get(url);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getAdminHoldings = createAsyncThunk(
    "holding/getAdmin",
    async (_, { rejectWithValue }) => {
        try {

            const { data } = await axios.get('/api/holdinginformation?includeUserData=true');
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getHoldingById = createAsyncThunk(
    "holding/getById",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/holdinginformation/${id}`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const createHolding = createAsyncThunk(
    "holding/new",
    async (props: IHoldingValues, { rejectWithValue }) => {
        try {
            const { reference, region, systemDescription } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post('/api/holdinginformation', { reference, region, systemDescription }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const deleteHolding = createAsyncThunk(
    "holding/delete",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.delete(`/api/holdinginformation/${id}`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const removeHolding = createAsyncThunk(
    "holding/remove",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.delete(`/api/holdinginformation/remove/${id}`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const updateHolding = createAsyncThunk(
    "holding/update",
    async (props: IHoldingValues, { rejectWithValue }) => {
        try {
            const { id, reference, region, systemDescription } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.put(`/api/holdinginformation/${id}`, { reference, region, systemDescription }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);


const holdingSlice = createSlice({
    name: "holding",
    initialState,
    reducers: {
        reset(state) {
            state.success = undefined;
            state.isDeleted = undefined;
            state.isUpdated = undefined;
        },
        clearErrors(state) {
            state.errors = undefined;
        }
    },
    extraReducers(builder) {
        builder
        .addCase(getHoldings.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getHoldings.fulfilled, (state, action) => {
            state.loading = false;
            state.holdings = action.payload.data;
            state.paginationMetaData = action.payload.paginationMetaData;
        })
        .addCase(getHoldings.rejected, (state, action) => {
            state.loading = false;
            state.holdings = undefined;
            state.paginationMetaData = undefined;
            state.errors = action.payload;
        })
        .addCase(getAdminHoldings.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getAdminHoldings.fulfilled, (state, action) => {
            state.loading = false;
            state.holdings = action.payload.data;
            state.paginationMetaData = action.payload.paginationMetaData;
        })
        .addCase(getAdminHoldings.rejected, (state, action) => {
            state.loading = false;
            state.holdings = undefined;
            state.paginationMetaData = undefined;
            state.errors = action.payload;
        })

        .addCase(getHoldingById.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getHoldingById.fulfilled, (state, action) => {
            state.loading = false;
            state.holding = action.payload;
        })
        .addCase(getHoldingById.rejected, (state, action) => {
            state.loading = false;
            state.holding = undefined;
            state.errors = action.payload;
        })

        .addCase(createHolding.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(createHolding.fulfilled, (state, action) => {
            state.loading = false;
            state.holding = action.payload;
            state.success = true;
        })
        .addCase(createHolding.rejected, (state, action) => {
            state.loading = false;
            state.holding = undefined;
            state.errors = action.payload;
        })

        .addCase(deleteHolding.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(deleteHolding.fulfilled, (state, action) => {
            state.loading = false;
            state.isDeleted = true;
        })
        .addCase(deleteHolding.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })

        .addCase(removeHolding.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(removeHolding.fulfilled, (state, action) => {
            state.loading = false;
            state.isDeleted = true;
        })
        .addCase(removeHolding.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })

        .addCase(updateHolding.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateHolding.fulfilled, (state, action) => {
            state.loading = false;
            state.isUpdated = true;
        })
        .addCase(updateHolding.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })

        
    },
})

export const { clearErrors, reset } = holdingSlice.actions;
export default holdingSlice.reducer;