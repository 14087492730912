import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../../styles/Button.styled';
import { InputSubContainer, InputSubContainerRow, LandUseInputsContainer } from '../../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { purchasedOtherBeddingTypeValidationSchema } from '../ValidationSchemas';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { toast } from 'react-toastify';
import OtherBeddingRecord from './OtherBeddingRecord';
import { IOtherBeddingTypes } from '../Input.interface';
import { clearErrors, getFeedAndBeddingDataById, getFootprintById, putOtherBeddingDataById, reset } from '../../../features/footprint/footprintSlice';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import { IFeedAndBeddingData } from '../../../features/footprint/footprintSlice.interfaces';
import Loader from '../../layout/Loader';

type Props = {}

const OtherBeddingTypes = (props: Props) => {
    const [pulledData, setPulledData] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [otherLivestockPresent, setOtherLivestockPresent] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();
    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, feedAndBedding, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getFeedAndBeddingDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Bedding types saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/electricity");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }


        setActiveWideStep(4);
        setActiveMobileStep(3);

        dispatch(getDropdownValues({ section: "ForagesAndStrawBeddingInput" }))

    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (otherBedding: IFeedAndBeddingData["otherBedding"]) => {
        return otherBedding.map(element => {
            return {
                totalTonnagePurchasedAnnually: element.totalTonnage,
                packagingDisposalMethod: element.packagingDisposalMethod,
                estimatedDistanceFromDistributor: element.distributorDistance,
                otherBeddingType: element.otherBeddingType,
            }
        })
    }

    return (
        <div>
            <InputFormHeading>Purchased feed and bedding - other bedding types</InputFormHeading>
            {loading ? <Loader /> : <Formik
                initialValues={{
                    otherBeddingTypes: parseDataIntoForm(feedAndBedding?.otherBedding ?? [])
                }}
                validationSchema={purchasedOtherBeddingTypeValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 400);

                    var otherBeddings = values.otherBeddingTypes.map(element => {
                        return {
                            totalTonnage: element.totalTonnagePurchasedAnnually,
                            packagingDisposalMethod: element.packagingDisposalMethod,
                            distributorDistance: element.estimatedDistanceFromDistributor,
                            otherBeddingType: element.otherBeddingType,
                        }
                    })

                    dispatch(putOtherBeddingDataById(otherBeddings))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            Please enter the total tonnage of each bedding type purchased. Provide an estimate of the distance that bedding is transported from the distributor.
                        </InputFormDescription>

                        <FieldArray name="otherBeddingTypes">
                            {({ form, ...fieldArrayHelpers }) => {
                                const onAddClick = () => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    if (values.otherBeddingTypes.length >= ((dropdowns?.filter(element => element.theme === "Bedding").length) ?? 2)) {
                                        toast.dismiss();
                                        toast.error("Maximum number of supplementary feed types reached");
                                        return;
                                    }
                                    fieldArrayHelpers.push({
                                        id: uuidv4(),
                                        supplementaryFeedType: "",
                                        totalTonnagePurchasedAnnually: 0,
                                        packagingDisposalMethod: "",
                                        supplier: "",
                                        estimatedDistanceFromDistributor: "",
                                    });
                                };

                                const deleteRecordHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    form.setFieldValue(
                                        "otherBeddingTypes",
                                        form.values.otherBeddingTypes.filter((record: { id: string; }) => record.id !== id)
                                    );
                                }

                                return (
                                    <>
                                        <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={onAddClick} />

                                        {form.values.otherBeddingTypes.length === 0 && (
                                            <div>Click the green "plus" button above to add a record if you have any on your farm.</div>
                                        )}

                                        {form.values.otherBeddingTypes.map((record: IOtherBeddingTypes['otherBeddingTypes'], index: number) => (
                                            <OtherBeddingRecord
                                                index={index}
                                                key={index}
                                                deleteRecordHandler={deleteRecordHandler}
                                                errors={errors}
                                                touched={touched}
                                                inputIsLocked={inputIsLocked}
                                            />
                                        ))}
                                    </>
                                );
                            }}
                        </FieldArray>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton type="button" onClick={() => navigate("/input/purchasedSupplementaryFeeds")}>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}
        </div>
    )
}

export default OtherBeddingTypes