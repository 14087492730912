import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../../styles/Button.styled';
import { InputSubContainer, InputSubContainerRow, LandUseInputsContainer } from '../../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { purchasedFeedForageValidationSchema } from '../ValidationSchemas';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import { IForagesAndStrawBedding } from '../Input.interface';
import ForageRecord from './ForageRecord';
import { toast } from 'react-toastify';
import { clearErrors, getFeedAndBeddingDataById, getFootprintById, putPurchasedForageDataById, reset } from '../../../features/footprint/footprintSlice';
import Loader from '../../layout/Loader';
import { IFeedAndBeddingData } from '../../../features/footprint/footprintSlice.interfaces';

type Props = {}

const ForagesAndStrawBedding = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [otherLivestockPresent, setOtherLivestockPresent] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();
    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, feedAndBedding, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getFeedAndBeddingDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Forage saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/purchasedSupplementaryFeeds");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        setActiveWideStep(4);
        setActiveMobileStep(3);

        dispatch(getDropdownValues({ section: "ForagesAndStrawBeddingInput" }))

    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (forage: IFeedAndBeddingData["forage"]) => {

        var data = forage.map(element => {
            return {
                forageType: element.forageType,
                metric: element.metric,
                baleType: element.baleType ?? "",
                numOfAnnuallyPurchasedBale: element.numberOfBales,
                totalTonnagePurchasedAnnually: element.totalTonnage,
                balePlasticWasteDisposal: element.packagingDisposalMethod,
                estimatedDistanceFromDistributor: element.distributorDistance,
            }
        })

        return data;
    }

    return (
        <div>
            <InputFormHeading>Purchased feed and bedding - forages and straw bedding</InputFormHeading>
            {loading ? <Loader /> : <Formik
                initialValues={{
                    foragesAndStrawBedding: parseDataIntoForm(feedAndBedding?.forage ?? [])
                }}
                validationSchema={purchasedFeedForageValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);
                    var forage = values.foragesAndStrawBedding.map(element => {
                        return {
                            forageType: element.forageType,
                            metric: element.metric,
                            baleType: element.baleType,
                            numberOfBales: element.numOfAnnuallyPurchasedBale,
                            totalTonnage: element.totalTonnagePurchasedAnnually,
                            packagingDisposalMethod: element.balePlasticWasteDisposal,
                            distributorDistance: element.estimatedDistanceFromDistributor,
                        }
                    })

                    dispatch(putPurchasedForageDataById(forage))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <InputForm onSubmit={handleSubmit}>
                        <InputFormDescription>
                            Please provide details of all purchased forage and straw bedding (but omit any produced onsite, as the carbon emissions for those will be accounted for elsewhere within the tool).  Click on the downward arrow to record the additional information required, such as volume acquired and distance from the distributor.  Please click on the green + symbol to add additional types of forage and straw bedding.

                        </InputFormDescription>

                        <FieldArray name="foragesAndStrawBedding">
                            {({ form, ...fieldArrayHelpers }) => {
                                const onAddClick = () => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    if (values.foragesAndStrawBedding.length >= ((dropdowns?.filter(element => element.theme === "ForagesAndStraw").length) ?? 4)) {
                                        toast.dismiss();
                                        toast.error("Maximum number of forage types reached");
                                        return;
                                    }
                                    fieldArrayHelpers.push({
                                        id: uuidv4(),
                                        forageType: "",
                                        metric: "",
                                        baleType: "",
                                        numOfAnnuallyPurchasedBale: 0,
                                        totalTonnagePurchasedAnnually: 0,
                                        balePlasticWasteDisposal: "",
                                        estimatedDistanceFromDistributor: "",
                                    });
                                };

                                const deleteRecordHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    form.setFieldValue(
                                        "foragesAndStrawBedding",
                                        form.values.foragesAndStrawBedding.filter((record: { id: string; }) => record.id !== id)
                                    );
                                }

                                // console.log(errors)

                                return (
                                    <>
                                        <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={onAddClick} />

                                        {form.values.foragesAndStrawBedding.length === 0 && (
                                            <div>Click the green "+" button above to add a record if you have any on your farm.</div>
                                        )}

                                        {form.values.foragesAndStrawBedding.map((record: IForagesAndStrawBedding['foragesAndStrawBedding'], index: number) => (
                                            <ForageRecord
                                                index={index}
                                                key={index}
                                                deleteRecordHandler={deleteRecordHandler}
                                                errors={errors}
                                                touched={touched}
                                                inputIsLocked={inputIsLocked}
                                            />
                                        ))}
                                    </>
                                );
                            }}
                        </FieldArray>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton type="button" onClick={() => navigate("/input/manureManagement")}>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}
        </div>
    )
}

export default ForagesAndStrawBedding