import React, { useEffect, useState } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { UtilityButton } from '../../styles/Button.styled';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { clearErrors, getElectricityAndFuelDataById, getFootprintById, putPurchasedFuelDetailsById, reset } from '../../../features/footprint/footprintSlice';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import tippy from 'tippy.js';

type Props = {}

interface IPurchasedFuelErrors {
    petrolCars?: string,
    dieselCars?: string,
    fourByFours?: string,
    pickupTrucks?: string,
    vans?: string,
    lgvThreePointFive?: string,
    lgvSevenPointFive?: string,
    hgvOverSevenPointFive?: string
}

const PurchasedFuel = (props: Props) => {
    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();

    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, electricityAndFuel, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        setActiveWideStep(5);
        setActiveMobileStep(4);

        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getElectricityAndFuelDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        if (success) {
            toast.dismiss();
            toast.success("Purchased fuel saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/horseTransport");
        }

    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (purchasedFuelDetails: { vehicleType: string, annualDistance: number }[]) => {
        var dieselCars = purchasedFuelDetails.find(x => x.vehicleType === "CarsDiesel");
        var petrolCars = purchasedFuelDetails.find(x => x.vehicleType === "CarsPetrol");
        var fourByFours = purchasedFuelDetails.find(x => x.vehicleType === "FourByFours");
        var pickupTrucks = purchasedFuelDetails.find(x => x.vehicleType === "PickupTrucks");
        var vans = purchasedFuelDetails.find(x => x.vehicleType === "Vans");
        var lgvsUpToThreePointFive = purchasedFuelDetails.find(x => x.vehicleType === "LGVsUpTo3_5t");
        var lgvsUpToSevenPointFive = purchasedFuelDetails.find(x => x.vehicleType === "LGVsUpTo7_5t");
        var hgvsOverSevenPointFive = purchasedFuelDetails.find(x => x.vehicleType === "HGVsOver7_5t");

        var defaultValues = {
            petrolCars: 0,
            dieselCars: 0,
            fourByFours: 0,
            pickupTrucks: 0,
            vans: 0,
            lgvThreePointFive: 0,
            lgvSevenPointFive: 0,
            hgvOverSevenPointFive: 0
        }

        if (!(
            dieselCars?.annualDistance ||
            petrolCars?.annualDistance ||
            fourByFours?.annualDistance ||
            pickupTrucks?.annualDistance ||
            vans?.annualDistance ||
            lgvsUpToThreePointFive?.annualDistance ||
            lgvsUpToSevenPointFive?.annualDistance ||
            hgvsOverSevenPointFive?.annualDistance
        )) {
            return defaultValues
        } else {
            return {
                petrolCars: petrolCars?.annualDistance,
                dieselCars: dieselCars?.annualDistance,
                fourByFours: fourByFours?.annualDistance,
                pickupTrucks: pickupTrucks?.annualDistance,
                vans: vans?.annualDistance,
                lgvThreePointFive: lgvsUpToThreePointFive?.annualDistance,
                lgvSevenPointFive: lgvsUpToSevenPointFive?.annualDistance,
                hgvOverSevenPointFive: hgvsOverSevenPointFive?.annualDistance
            }
        }
    }

    tippy('.petrol-cars-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections.",
    });

    tippy('.diesel-cars-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections.",
    });

    tippy('.fours-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections. ",
    });

    tippy('.pickup-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections.",
    });

    tippy('.vans-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections.",
    });

    tippy('.lgv-three-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections. ",
    });

    tippy('.lgv-seven-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections. ",
    });

    tippy('.hgv-tooltip', {
        content: "Enter the total annual distance travelled by this mode of transport using fuel purchased from service stations. This should only include purchased fuel use not captured in the previous sections. ",
    });


    return (
        <div>
            <InputFormHeading>Electricity and fuel - fuel purchased from service stations</InputFormHeading>
            {loading ? <Loader /> : <Formik
                initialValues={parseDataIntoForm(electricityAndFuel?.purchasedFuel?.purchasedFuelDetails ?? [])}
                validate={values => {
                    const errors: IPurchasedFuelErrors = {};

                    if (!values.petrolCars && values.petrolCars !== 0) {
                        errors.petrolCars = 'Required';
                    }
                    if (!values.dieselCars && values.dieselCars !== 0) {
                        errors.dieselCars = 'Required';
                    }
                    if (!values.fourByFours && values.fourByFours !== 0) {
                        errors.fourByFours = 'Required';
                    }
                    if (!values.pickupTrucks && values.pickupTrucks !== 0) {
                        errors.pickupTrucks = 'Required';
                    }
                    if (!values.vans && values.vans !== 0) {
                        errors.vans = 'Required';
                    }
                    if (!values.lgvThreePointFive && values.lgvThreePointFive !== 0) {
                        errors.lgvThreePointFive = 'Required';
                    }
                    if (!values.lgvSevenPointFive && values.lgvSevenPointFive !== 0) {
                        errors.lgvSevenPointFive = 'Required';
                    }
                    if (!values.hgvOverSevenPointFive && values.hgvOverSevenPointFive !== 0) {
                        errors.hgvOverSevenPointFive = 'Required';
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);

                    // call dispatch
                    dispatch(putPurchasedFuelDetailsById({
                        petrolCars: values.petrolCars ?? 0,
                        dieselCars: values.dieselCars ?? 0,
                        fourByFours: values.fourByFours ?? 0,
                        pickupTrucks: values.pickupTrucks ?? 0,
                        vans: values.vans ?? 0,
                        lgvThreePointFive: values.lgvThreePointFive ?? 0,
                        lgvSevenPointFive: values.lgvSevenPointFive ?? 0,
                        hgvOverSevenPointFive: values.hgvOverSevenPointFive ?? 0
                    }))

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            Use this section to provide estimates for fuel consumption not captured in the previous section, including fuel purchased at service stations.<br />

                            For vehicles using fuel purchased from service stations, enter the annual total distance travelled for all vehicles of each type.

                        </InputFormDescription>

                        <table data-role="table" data-mode="reflow" className="ui-table ui-responsive ui-table-reflow my-custom-breakpoint my-4">
                            <thead>
                                <tr>
                                    <th data-priority="persist">Vehicle</th>
                                    <th data-priority="1">Total annual distance travelled (km)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Petrol cars
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="petrolCars"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.petrolCars}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.petrolCars && touched.petrolCars && errors.petrolCars}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className="petrol-cars-tooltip" src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Diesel cars
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="dieselCars"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dieselCars}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.dieselCars && touched.dieselCars && errors.dieselCars}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='diesel-cars-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            4x4s
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="fourByFours"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.fourByFours}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.fourByFours && touched.fourByFours && errors.fourByFours}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='fours-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Pickup trucks
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="pickupTrucks"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.pickupTrucks}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.pickupTrucks && touched.pickupTrucks && errors.pickupTrucks}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='pickup-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Vans
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="vans"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.vans}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.vans && touched.vans && errors.vans}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='vans-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            LGVs up to 3.5t
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="lgvThreePointFive"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lgvThreePointFive}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.lgvThreePointFive && touched.lgvThreePointFive && errors.lgvThreePointFive}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='lgv-three-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            LGVs up to 7.5t
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="lgvSevenPointFive"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lgvSevenPointFive}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.lgvSevenPointFive && touched.lgvSevenPointFive && errors.lgvSevenPointFive}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='lgv-seven-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            HGVs over 7.5t
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="hgvOverSevenPointFive"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.hgvOverSevenPointFive}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.hgvOverSevenPointFive && touched.hgvOverSevenPointFive && errors.hgvOverSevenPointFive}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='hgv-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton type='button' onClick={() => navigate("/input/contractorsFuel")}>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}
        </div>
    )
}

export default PurchasedFuel