import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../styles/Button.styled';
import { LandUseInputsContainer } from '../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { horsesValidationSchema } from './ValidationSchemas';
import { IDetailsOfHorsesOnSite } from './Input.interface';
import HorsesOnSiteRecord from './HorsesOnSiteRecord';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../input/Input';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getDropdownValues } from '../../features/operations/operationsSlice';
import { toast } from 'react-toastify';
import { clearErrors, getFootprintById, getHorsesOnSiteDataById, putHorsesOnSiteDataById, reset } from '../../features/footprint/footprintSlice';
import { IHorseDetailsData } from '../../features/footprint/footprintSlice.interfaces';
import Loader from '../layout/Loader';

type Props = {}

const HorsesOnSite = (props: Props) => {
    const [pulledData, setPulledData] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [otherLivestockPresent, setOtherLivestockPresent] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();
    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, horseDetails, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getHorsesOnSiteDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Horse details saved successfully");
            dispatch(reset())
            if (nextPage) {
                if (otherLivestockPresent) navigate("/input/otherLivestock");
                else navigate("/input/manureManagement");
            }
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        setActiveWideStep(2);
        setActiveMobileStep(2);

        dispatch(getDropdownValues({ section: "HorseOnSiteInput" }))

    }, [dispatch, errors, navigate, nextPage, otherLivestockPresent, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (horseDetails: IHorseDetailsData[]) => {
        var horses = horseDetails.map(element => {
            var parsedHousingManagement = element.housingManagementList.map(element => {
                return {
                    managementType: element.housingManagementType,
                    percentageOfTimeHoused: element.percentage * 100,
                    id: uuidv4(),
                }
            })

            return {
                id: uuidv4(),
                animalType: element.horseType,
                numOfAnimals: element.numberOfAnimals,
                reference: element.reference,
                avgTimeSpentOnSite: element.durationOfStayCategory,
                housingManagement: parsedHousingManagement
            }
        })
        return horses
    }

    return (
        <>
            <LandUseInputsContainer>
                <InputFormHeading>Details of horses on site</InputFormHeading>
                {loading ? <Loader /> : <Formik
                    initialValues={{
                        horses: parseDataIntoForm(horseDetails ?? [])
                    }}
                    validationSchema={horsesValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            setSubmitting(false);
                        }, 400);

                        // validate housing management percentages sum to 100
                        var invalidManagement = false
                        values.horses.forEach(element => {
                            // console.log(element.housingManagement.reduce((n, { percentageOfTimeHoused }) => n + percentageOfTimeHoused, 0))
                            if (element.housingManagement.reduce((n, { percentageOfTimeHoused }) => Number(n) + Number(percentageOfTimeHoused), 0) > 100) {
                                toast.dismiss()
                                toast.error("Please check your housing management percentages and make sure each sum is not more than 100");
                                invalidManagement = true
                            }
                            return
                        })
                        if (invalidManagement) return

                        var horses = values.horses.map(element => {
                            var parsedHousingManagement = element.housingManagement.map(management => {
                                return {
                                    housingManagementType: management.managementType,
                                    percentage: management.percentageOfTimeHoused / 100
                                }
                            })
                            return {
                                numberOfAnimals: element.numOfAnimals,
                                horseType: element.animalType,
                                durationOfStayCategory: element.avgTimeSpentOnSite,
                                reference: element.reference,
                                housingManagementList: parsedHousingManagement
                            }
                        })

                        dispatch(putHorsesOnSiteDataById(horses))
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <InputForm onSubmit={handleSubmit}>

                            <InputFormDescription>
                                In this section we are aiming to understand how many horses are present on the farm for how many days – i.e. calculate total horse days.  If all horses are managed in a similar way year round they can be grouped together, but if there are different practices for different groups you can use the green plus to add additional categories of horses, to help enter data more accurately.
                            </InputFormDescription>

                            <FieldArray name="horses">
                                {({ form, ...fieldArrayHelpers }) => {
                                    const onAddClick = () => {
                                        if (inputIsLocked()) {
                                            toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                            return;
                                        }
                                        fieldArrayHelpers.push({
                                            id: uuidv4(),
                                            animalType: "",
                                            numOfAnimals: 0,
                                            avgTimeSpentOnSite: "",
                                            housingManagement: [{
                                                id: uuidv4(),
                                                managementType: "",
                                                percentageOfTimeHoused: 0
                                            }],
                                            reference: ''
                                        });
                                    };

                                    const deleteRecordHandler = (id: string) => {
                                        if (inputIsLocked()) {
                                            toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                            return;
                                        }
                                        form.setFieldValue(
                                            "horses",
                                            form.values.horses.filter((record: { id: string; }) => record.id !== id)
                                        );
                                    }

                                    const addHousingManagementHandler = (id: string) => {
                                        if (inputIsLocked()) {
                                            toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                            return;
                                        }
                                        let horses = form.values.horses
                                        let recordIndex = horses.findIndex((element: { id: string, housingManagement: any[] }) => element.id === id)
                                        if (recordIndex === -1) return;
                                        let record = horses[recordIndex]

                                        record.housingManagement.push({
                                            id: uuidv4(),
                                            managementType: "",
                                            percentageOfTimeHoused: 0
                                        });

                                        horses[recordIndex] = record;

                                        form.setFieldValue(
                                            "horses",
                                            horses
                                        );
                                    }

                                    const deleteHousingManagementHandler = (id: string, managementId: string) => {
                                        if (inputIsLocked()) {
                                            toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                            return;
                                        }
                                        let horses = form.values.horses
                                        let recordIndex = horses.findIndex((element: { id: string, housingManagement: any[] }) => element.id === id)
                                        if (recordIndex === -1) return;
                                        let record = horses[recordIndex]

                                        record.housingManagement = record.housingManagement.filter((element: { id: string; }) => element.id !== managementId)
                                        horses[recordIndex] = record;

                                        form.setFieldValue(
                                            "horses",
                                            horses
                                        );
                                    }


                                    return (
                                        <>
                                            <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={onAddClick} />
                                            {form.values.horses.map((record: IDetailsOfHorsesOnSite['horses'], index: number) => (
                                                <HorsesOnSiteRecord
                                                    index={index}
                                                    key={index}
                                                    deleteRecordHandler={deleteRecordHandler}
                                                    errors={errors}
                                                    touched={touched}
                                                    addHousingManagementHandler={addHousingManagementHandler}
                                                    deleteHousingManagementHandler={deleteHousingManagementHandler}
                                                    inputIsLocked={inputIsLocked}
                                                />
                                            ))}
                                        </>
                                    );
                                }}
                            </FieldArray>

                            <div className='d-flex w-100 justify-content-center'>
                                <InputFormButtonsContainer>
                                    <InputFormButton type="button" onClick={() => navigate("/input/landUse")}>
                                        Back
                                    </InputFormButton>

                                    {inputIsLocked() ? (
                                        <>
                                            <InputFormSaveButton type="button" disabled={isSubmitting} onClick={() => setNextPage(true)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                Save & Continue
                                            </InputFormSaveButton>
                                        </>
                                    ) : (
                                        <>
                                            <InputFormButton disabled={isSubmitting}>
                                                Save
                                            </InputFormButton>

                                            <InputFormSaveButton type="button" disabled={isSubmitting} onClick={() => setNextPage(true)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                Save & Continue
                                            </InputFormSaveButton>
                                        </>
                                    )}
                                </InputFormButtonsContainer>
                            </div>
                            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="false">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Other livestock</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                                        </div>
                                        <div className="modal-body">
                                            Are there any other livestock present on your stud farm?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setOtherLivestockPresent(false)}>No</button>
                                            <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setOtherLivestockPresent(true)}>Yes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </InputForm>
                    )}
                </Formik>}

            </LandUseInputsContainer>

        </>
    )
}

export default HorsesOnSite