import React, { useEffect, useState } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { UtilityButton } from '../styles/Button.styled';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../input/Input';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getDropdownValues } from '../../features/operations/operationsSlice';
import { clearErrors, getFootprintById, getLandUseDataById, putManureManagementDataById, reset } from '../../features/footprint/footprintSlice';
import Loader from '../layout/Loader';
import { toast } from 'react-toastify';
import tippy from 'tippy.js';

type Props = {}

interface IHousingManureManagementErrors {
    fieldManureManagementMethod?: string,
    muckHeapsAreCovered?: string,
    muckHeapsOnImpermeableBase?: string,
    muckHeapDisposalMethod?: string,
    muckHeapDisposalFrequency?: string
}

const HorseManureManagement = (props: Props) => {

    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();
    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, landUse, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {

        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getLandUseDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Manure management saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/purchasedFeedForages");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        tippy('.field-manure-managed-tooltip', {
            content: "Select the most compatible option from the dropdown. If field manures are removed more than weekly but less than daily, selected weekly picking. If field manures are removed more than monthly but less than weekly, selected monthly picking. If manure is not removed from fields and is harrowed to spread evenly, select harrowed.",
        });

        tippy('.cover-muck-tooltip', {
            content: "Covering muck heaps reduces rain water entry and can reduce leaching of nutrients out of muckheap and into water.  The cover can also reduce loss of emissions.",
        });

        tippy('.imperm-base-tooltip', {
            content: "An impermeable base would typically be concreate and should include drainage to capture effluent.  Reducing loss of effluent will protect water and also reduce indirect emissions associated with its loss.",
        });

        tippy('.method-heap-disposal-tooltip', {
            content: "Where manure is disposed of by application to your own land there will be emissions associated with application, but it will displace the need for manufactured fertilisers.  Where muck is exported no application emissions are allocated to the stud farm.",
        });

        tippy('.freq-muck-disposal-tooltip', {
            content: "Select frequency with which muck is either exported from the holding or applied to the fields.",
        });


        setActiveWideStep(3);

        dispatch(getDropdownValues({ section: "HorseManureInput" }))

    }, [dispatch, setActiveWideStep, loading, pulledData, success, navigate, errors, nextPage])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    return (
        <div>
            <InputFormHeading>Horse manure management</InputFormHeading>
            {loading ? <Loader /> :
                <Formik
                    initialValues={
                        landUse ?
                            {
                                fieldManureManagementMethod: landUse?.manureManagement?.manureType,
                                muckHeapsAreCovered: landUse?.manureManagement?.isMuckHeapsCovered,
                                muckHeapsOnImpermeableBase: landUse?.manureManagement?.isMuckHeapsOnSolidBase,
                                muckHeapDisposalMethod: landUse?.manureManagement?.manureDisposalMethod,
                                muckHeapDisposalFrequency: landUse?.manureManagement?.manureRemovalFrequency
                            }
                            : {
                                fieldManureManagementMethod: '',
                                muckHeapsAreCovered: false,
                                muckHeapsOnImpermeableBase: false,
                                muckHeapDisposalMethod: '',
                                muckHeapDisposalFrequency: ''
                            }}
                    validate={values => {
                        const errors: IHousingManureManagementErrors = {};

                        if (!values.fieldManureManagementMethod) {
                            errors.fieldManureManagementMethod = 'Required';
                        }
                        if (!values.muckHeapDisposalMethod) {
                            errors.muckHeapDisposalMethod = 'Required';
                        }
                        if (!values.muckHeapDisposalFrequency) {
                            errors.muckHeapDisposalFrequency = 'Required';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            setSubmitting(false);
                        }, 400);

                        // console.log(values)

                        dispatch(putManureManagementDataById({
                            manureType: values.fieldManureManagementMethod ?? '',
                            manureDisposalMethod: values.muckHeapDisposalMethod ?? '',
                            isMuckHeapsCovered: Boolean(values.muckHeapsAreCovered),
                            isMuckHeapsOnSolidBase: Boolean(values.muckHeapsOnImpermeableBase),
                            manureRemovalFrequency: values.muckHeapDisposalFrequency ?? '',
                        }))
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <InputForm onSubmit={handleSubmit}>

                            <InputFormDescription>
                                Please provide details on how horse manure is managed on this site.
                            </InputFormDescription>

                            <StandardInputGroup>
                                <label>
                                    How are field manures managed?
                                </label>

                                <div className='d-flex align-items-center'>
                                    <div>
                                        <Select
                                            name="fieldManureManagementMethod"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.fieldManureManagementMethod}
                                            className='form-control'
                                            data-role='none'
                                            disabled={inputIsLocked()}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {dropdowns?.filter(element => element.theme === "FieldManureManagement").map(value => {
                                                return <option key={value.key} value={value.key}>{value.description}</option>
                                            })}
                                        </Select>
                                        <ErrorText>{errors.fieldManureManagementMethod && touched.fieldManureManagementMethod && errors.fieldManureManagementMethod}</ErrorText>
                                    </div>
                                    <UtilityButton className='field-manure-managed-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                </div>
                            </StandardInputGroup>

                            <StandardInputGroup>
                                <label htmlFor='muckHeapsAreCovered'>
                                    Please tick if muck heaps are covered for majority of the reporting period
                                </label>

                                <div className='d-flex align-items-center'>
                                    <div className='d-flex'>
                                        <ErrorText className='mx-2'>{errors.muckHeapsAreCovered && touched.muckHeapsAreCovered && errors.muckHeapsAreCovered}</ErrorText>
                                        <CheckBox
                                            type="checkbox"
                                            name="muckHeapsAreCovered"
                                            id='muckHeapsAreCovered'
                                            data-role='none'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.muckHeapsAreCovered}
                                            className='form-control'
                                            disabled={inputIsLocked()}
                                        />
                                    </div>
                                    <UtilityButton className='cover-muck-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                </div>
                            </StandardInputGroup>

                            <StandardInputGroup>
                                <label htmlFor='muckHeapsOnImpermeableBase'>
                                    Please tick if muck heaps are on an impermeable base for majority of the reporting period
                                </label>

                                <div className='d-flex align-items-center'>
                                    <div className='d-flex'>
                                        <ErrorText className='mx-2'>{errors.muckHeapsOnImpermeableBase && touched.muckHeapsOnImpermeableBase && errors.muckHeapsOnImpermeableBase}</ErrorText>
                                        <CheckBox
                                            type="checkbox"
                                            name="muckHeapsOnImpermeableBase"
                                            id='muckHeapsOnImpermeableBase'
                                            data-role='none'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.muckHeapsOnImpermeableBase}
                                            className='form-control'
                                            disabled={inputIsLocked()}
                                        />
                                    </div>
                                    <UtilityButton className='imperm-base-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                </div>
                            </StandardInputGroup>

                            <StandardInputGroup>
                                <label>
                                    Method of muck heap disposal
                                </label>

                                <div className='d-flex align-items-center'>
                                    <div>
                                        <Select
                                            name="muckHeapDisposalMethod"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.muckHeapDisposalMethod}
                                            className='form-control'
                                            data-role='none'
                                            disabled={inputIsLocked()}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {dropdowns?.filter(element => element.theme === "ManureDisposalOption").map(value => {
                                                return <option key={value.key} value={value.key}>{value.description}</option>
                                            })}
                                        </Select>
                                        <ErrorText>{errors.muckHeapDisposalMethod && touched.muckHeapDisposalMethod && errors.muckHeapDisposalMethod}</ErrorText>
                                    </div>
                                    <UtilityButton className='method-heap-disposal-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                </div>
                            </StandardInputGroup>

                            <StandardInputGroup>
                                <label>
                                    Frequency of muck heap disposal
                                </label>

                                <div className='d-flex align-items-center'>
                                    <div>
                                        <Select
                                            name="muckHeapDisposalFrequency"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.muckHeapDisposalFrequency}
                                            className='form-control'
                                            data-role='none'
                                            disabled={inputIsLocked()}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {dropdowns?.filter(element => element.theme === "ManureHeapRemovalFrequency").map(value => {
                                                return <option key={value.key} value={value.key}>{value.description}</option>
                                            })}
                                        </Select>
                                        <ErrorText>{errors.muckHeapDisposalFrequency && touched.muckHeapDisposalFrequency && errors.muckHeapDisposalFrequency}</ErrorText>
                                    </div>
                                    <UtilityButton className='freq-muck-disposal-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                </div>
                            </StandardInputGroup>

                            <div className='d-flex w-100 justify-content-center'>
                                <InputFormButtonsContainer>
                                    <InputFormButton type="button" onClick={() => navigate("/input/horses")}>
                                        Back
                                    </InputFormButton>

                                    {inputIsLocked() ? (
                                        <>
                                            <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                                Continue
                                            </InputFormSaveButton>
                                        </>
                                    ) : (
                                        <>
                                            <InputFormButton disabled={isSubmitting}>
                                                Save
                                            </InputFormButton>

                                            <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                                Save & Continue
                                            </InputFormSaveButton>
                                        </>
                                    )}
                                </InputFormButtonsContainer>
                            </div>
                        </InputForm>
                    )}
                </Formik>
            }
        </div>
    )
}

export default HorseManureManagement